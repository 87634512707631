<template>
    <div class="export-container">
        <div class="export-body">
            <h2>Export data</h2>
            <div class="description">
                You can export the data of all the hospitals you have the “exporter” role in to an .xlsx file. If you have the “superexporter” role you can export the data from all hospitals. 
            </div>
            <b-dropdown class="m-2 export-button" variant="outline-secondary" right text="Export" split>
                <template #button-content>
                    <div class="left-button">
                        <feather-icon
                            icon="DownloadIcon"
                            class="text-muted"
                            size="20"
                        />
                        <div>Export</div>
                        <span>&nbsp;</span>
                    </div>
                </template>
                <!-- <b-dropdown-item>Hospital to export from</b-dropdown-item> -->
                <b-dropdown-item v-for="item in hospitals" :key="item.hospital_id" @click="downloadExcel(item._id)">{{ item.hospital_id }}</b-dropdown-item>
                <b-dropdown-divider v-if="isSuper"></b-dropdown-divider>
                <b-dropdown-item @click="downloadExcel(-1)" v-if="isSuper">All hospitals</b-dropdown-item>
            </b-dropdown>
            
        </div>
    </div>
</template>
<script>
import { BDropdown, BDropdownItem, BDropdownDivider } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

export default {
    components: {
        BDropdown, BDropdownItem, BDropdownDivider
    },
    data() {
        return {
            hospitals: [],
            user: JSON.parse(localStorage.getItem('userData'))
        }
    },
    mounted() {
        this.fetchHospitals()
    },
    computed: {
        isSuper() {
            return this.user.is_superexporter || this.user.is_admin
        }
    },
    methods: {
        logout() {
            // Remove userData from localStorage
            // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

            // Remove userData from localStorage
            localStorage.removeItem('userData')

            // Reset ability
            this.$ability.update(initialAbility)

            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
        },
        fetchHospitals() {
            this.$http.get('/hospitals/list_for_export')
                .then(res => {
                    this.hospitals = res.data.hospitals
                })
                .catch(err => {
                    if (err.response && err.response.data && err.response.data.expired) {
                        this.logout();
                        return;
                    }
                    this.showToast('danger', 'Error', 'An error occured while fetching hospitals.')
                })
        },
        showToast(variant, title, content) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: 'BellIcon',
                    text: content,
                    variant,
                },
            })
        },
        downloadExcel(id) {
            this.$http.post('/exports/download_for_hospitals', { id })
                .then(res => {
                    window.open(res.data.url + localStorage.getItem('accessToken') + `?hospital_id=${id}`, "_blank")
                })
                .catch(err => {
                    this.showToast('danger', 'Error', err.response.data.message)
                })
            
        }
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/export-data.scss';

</style>
