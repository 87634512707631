<template>
  <div class="dashboard">
    <p>
      Search for patients by: Patient ID (without $), hospital ID, date of birth
      or implantation date
    </p>
    <!-- Searchbar -->
    <div class="dashboard-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-append>
            <b-form-input
              v-model="searchKey"
              placeholder="31.12.1999"
              class="search-patient"
              debounce="500"
            />
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <b-card no-body class="mb-0 mt-2">
      <b-table
        ref="refUserListTable"
        class="position-relative patient-table"
        :items="patients"
        responsive
        :fields="tableColumns"
        primary-key="id"
        hover
        selectable
        show-empty
        empty-text="No matching records found"
        thead-class="hidden-header"
        @row-selected="viewPatient"
      >
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.patient_id)"
                :variant="`light-primary`"
                :to="{ name: 'patient-info', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'patient-info', params: { id: data.item.id } }"
              class="font-weight-bold text-nowrap patient-id"
            >
              {{ data.item.patient_id }}
            </b-link>
            <small class="text-muted">{{ data.item.hospital_id }}</small>
          </b-media>
        </template>

        <!-- Column: Birth -->
        <template #cell(date_of_birth)="data">
          <div class="normal-patient-cell">
            <p class="text-muted">Geburtsdatum</p>
            <p>{{ data.item.date_of_birth || "&nbsp;" }}</p>
          </div>
        </template>

        <!-- Column: Admission -->
        <template #cell(admission)="data">
          <div class="normal-patient-cell">
            <p class="text-muted">Aufnahmedatum</p>
            <p>{{ data.item.aufnahmedatum_date || "&nbsp;" }}</p>
          </div>
        </template>

        <!-- Column: Procedure -->
        <template #cell(procedure)="data">
          <div class="normal-patient-cell">
            <p class="text-muted">Implantationsdatum</p>
            <p>{{ data.item.implantationsdatum_date || "&nbsp;" }}</p>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <feather-icon
            icon="ArrowUpRightIcon"
            size="28"
            class="align-middle text-body action-cell"
            @click.stop="clickNewPatientView(data)"
          />
        </template>
      </b-table>
      <!-- <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div> -->
    </b-card>

    <new-patient @fectch-patient="fetchPatients"></new-patient>

    <separator />

    <export-data></export-data>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BTable,
  BCard,
  BMedia,
  BAvatar,
  BLink,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import NewPatient from "./NewPatient.vue";
import Separator from "./Separator.vue";
import ExportData from "./ExportData.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";

export default {
  components: {
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BTable,
    BCard,
    BMedia,
    BAvatar,
    BLink,
    BDropdown,
    BDropdownItem,
    NewPatient,
    Separator,
    ExportData,
  },
  data() {
    return {
      searchKey: "",
      patients: [],
      tableColumns: [
        { key: "user", sortable: true, tdClass: "normal-column" },
        { key: "date_of_birth", sortable: true, tdClass: "normal-column" },
        { key: "admission", sortable: true, tdClass: "normal-column" },
        { key: "procedure", sortable: true, tdClass: "normal-column" },
        { key: "actions", tdClass: "action-column" },
      ],
      avatarText,
    };
  },
  mounted() {
    this.fetchPatients();
  },
  watch: {
    searchKey(v, ov) {
      this.fetchPatients();
    },
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },
    fetchPatients() {
      this.$http
        .get(`/patients/list?searchKey=${this.searchKey}`)
        .then((res) => {
          this.patients = res.data.patients;
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.expired) {
            this.logout();
            return;
          }
          this.showToast(
            "danger",
            "Error",
            "An error occured while fetching patients."
          );
        });
    },
    clickNewPatientView(data) {
      let path =
        window.location.origin +
        this.$router.resolve({
          name: "patient-info",
          params: { id: data.item.id },
        }).href;
      window.open(path, "_blank");
    },
    viewPatient(items) {
      this.$router.push({ name: "patient-info", params: { id: items[0].id } });
    },
    showToast(variant, title, content) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: content,
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard.scss";
</style>